// Variables
$body-color: #ffffff;
$primary-color: #02bdd5;
$secondary-color: #7c7491;
$border-color:#e5e5e5;
$primary-font: 'Roboto', sans-serif;
$secondary-font: 'Roboto Condensed', sans-serif;
$text-color: #848484;
$gray: #eae9ec;
$light-gray: #fafafa;
$white: #fff;
$black: #222222;
$button-bg:#414141;